import {
	mapState,
	mapActions
} from 'vuex';
import Vue from 'vue';
import {
	Empty
} from 'vant';
import {
	Popup,
	Toast,
	Dialog
} from 'vant';
let Base64 = require('js-base64').Base64;

Vue.use(Empty);
Vue.use(Toast);
Vue.use(Dialog);
export default {
	name: 'warranty',
	components: {},
	data() {
		return {
			active: 0,
			// 保单列表
			warrantyList: [],
			waitPayList: [],
			UnderwritingList: [],
			takeEffectList: [],
			noback: true

		};
	},
	beforeCreate() {

	},
	created() {
		const noback1 = this.getUrlParam('noback');
		if (noback1 != '' && noback1 != undefined && noback1 != null) {
			this.noback = false
		} else {
			this.noback = true
		}
		const token = this.getUrlParam('token');
		if (token != '' && token != undefined && token != null) {
			localStorage.setItem('token', token)
		}
		Toast.loading({
			message: '加载中...',
			forbidClick: true,
			duration: 0,
		});
		const idName = Base64.decode(Base64.decode(this.getUrlParam('idName')));
		const idCard = this.getUrlParam('idCard');
		console.log(idName)
		this.selectBxOrderByCerNo({
			bxOrderInsurantCerNo: idCard,
			bxOrderInsurantName: idName
		}).then(res => {

			if (res.code == 200) {
				Toast.clear()
				this.takeEffectList = res.list
			}

		})
	},
	methods: {
		...mapActions('warranty', ['myWarranty', 'queryorder', 'selectBxOrderByCerNo']),
		todetail(ids, types, orderType) {

			if (orderType != undefined && orderType != null && orderType != 'null' && orderType != '') {
				this.$router.push({
					name: 'Wardetail',
					query: {
						id: ids,
						type: types,
						orderTypes: orderType
					}
				});
			} else {
				this.$router.push({
					name: 'Wardetail',
					query: {
						id: ids,
						type: types,
						orderTypes: 'a'
					}
				});
				//orderType为undefined，传0和1之外的值
			}

		},

		playApplication(id) {
			//申请理赔
			this.changeData()
			this.$router.push({
				name: 'settlement',
				query: {
					bxId: id
				}
			})
		},

		getUrlParam(name) {

			var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
			var r = window.location.search.substr(1).match(reg)
			if (r != null) return unescape(r[2])
			return null
		}




	},
};
